import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/app';
import * as serviceWorker from './services/serviceWorker';
import WebFont from 'webfontloader';

ReactDOM.render(<App />, document.getElementById('root'));


WebFont.load({
    custom: {
      families: ['tarrgetital'],
    },
  });

window.deactivateSwipe = false
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { Component } from 'react';
import './app.css';
import './shadow.css';
import { gsap } from 'gsap';
import { fetchData } from './utils/fetch-data';
import PortfolioCard from './components/portfolio-card';
import PortfolioContent from './components/portfolio-content';
import Header from './components/header';

class App extends Component {
  constructor(props) {
    super(props);
    this.myContent = React.createRef();
    this.state = {
      data: null,
    };
    this.home = null;
    this.portfolioContent = null;
    this.portfolioDictionary = {};
    window.addEventListener('resize', this.resize);
  }

  componentDidMount = async () => {
    this.contentRef = React.createRef();

    const fetchedData = await fetchData();
    for (let i = 0; i < fetchedData.portfolio.length; i++) {
      this.portfolioDictionary['#' + fetchedData.portfolio[i].id] =
        fetchedData.portfolio[i];
    }
    this.setState({ data: fetchedData });
    this.init();

    this.home = document.getElementById('home');
    this.portfolioContent = document.getElementById('portfolio-content');
    this.close = document.getElementById('close');
    this.resize();

    const hash = new URL(document.URL).hash;
    this.checkHash(hash);
    window.addEventListener('hashchange', () => {
      const hash = new URL(document.URL).hash;
      this.checkHash(hash);
    });
  };
  resize = () => {
    this.home.style.height = document.body.clientHeight + 'px';
    this.portfolioContent.style.height = document.body.clientHeight + 'px';
  };

  init() {
    // gsap.set(this.portfolioContent, { x: document.body.clientWidth });
  }

  itemClicked = index => {
    if (this.state.data.portfolio[index].externalUrl) {
      window.open(this.state.data.portfolio[index].externalUrl);
    } else {
      this.setHash(this.state.data.portfolio[index].id);
    }
  };
  itemRequestHome = () => {
    const allvideos = document.getElementsByClassName('video-itself');
    for (let i = 0; i < allvideos.length; i++) {
      const video = allvideos[i];
      video.pause();
      video.currentTime = 0;
    }
    this.setHash('/');
  };
  navigateToContent(hash) {
    const contentObject = this.portfolioDictionary[hash];
    setTimeout(() => {
      this.contentRef.current.createContent(contentObject.contentArray);
    }, 1000);
    this.contentRef.current.emptyContent();
    this.portfolioContent.style.backgroundColor = contentObject.background;
    this.goContent();
  }
  checkHash(hash) {
    if (this.portfolioDictionary[hash]) {
      this.navigateToContent(hash);
    } else {
      this.goHome();
    }
  }

  setHash(hash) {
    var url_ob = new URL(document.URL);
    url_ob.hash = hash;
    var new_url = url_ob.href;
    document.location.href = new_url;
  }

  renderPortfolio = () => {
    let listItems = null;
    if (this.state.data) {
      listItems = this.state.data.portfolio.map((item, index) => {
        item.index = index;
        return (
          <PortfolioCard key={index} item={item} father={this}></PortfolioCard>
        );
      });
    }
    return listItems;
  };

  goContent = () => {
    this.close.style.visibility = 'visible';
    gsap.set(this.close, {
      x: window.innerWidth,
    });
    gsap.to(this.close, {
      duration: 1.3,
      x: 0,
      ease: 'expo.out',
    });
    gsap.to(this.home, {
      duration: 1.8,
      left: '-100%',
      ease: 'expo.out',
    });
    gsap.set(this.portfolioContent, { rotation: 0.001 });
    gsap.to(this.portfolioContent, {
      duration: 1.2,
      left: 0,
      ease: 'expo.out',
    });
  };
  goHome = () => {
    if (this.close) this.close.style.visibility = 'hidden';
    gsap.to(this.home, {
      duration: 1,
      left: 0,
      ease: 'power4.out',
    });
    gsap.to(this.portfolioContent, {
      duration: 0.7,
      left: '100%',
      ease: 'power4.out',
    });
  };

  render() {
    return (
      <div className="container">
        <div
          id="close"
          className="close-container"
          onClick={this.itemRequestHome}
        >
          <img
            className="close-img"
            alt=""
            src="./images/ui/close-white-shadow.png"
          ></img>
        </div>
        <div className="home" id="home">
          <Header></Header>
          {/* <> */}
          <div className="grid-wrapper" id="all">
            {this.renderPortfolio()}
          </div>
          <div className="footer-home">
            <div>
              Otlabs is a company based in Italy and Uk. We do digital things,
              interactive applications, games.
            </div>
            <div>
              <a className="footer-link" href="mailto:rob@otlabs.net">
                Drop us a line
              </a>
            </div>
            <a
              className="footer-link"
              href="https://www.linkedin.com/in/robertocascavilla/"
              target="_blank"
            >
              Linkedin
            </a>
          </div>
          {/* </> */}
        </div>
        <div className="portfolio-content" id="portfolio-content">
          <PortfolioContent
            ref={this.contentRef}
            father={this}
          ></PortfolioContent>
        </div>
      </div>
    );
  }
}

export default App;

import React from 'react';
import './styles.css';
import { gsap } from 'gsap';

const Header = ({ index, item }) => {
  return (
    <div className="header">
      <img
        className="header-img"
        alt=""
        src="./images/header/header_big_big.png"
      ></img>

      <div className="links-container">
        <img
          onClick={() => {
            window.open('https://www.linkedin.com/in/robertocascavilla/');
          }}
          className="link-img"
          id="linkedin-icon"
          alt=""
          src="./images/ui/linkedin.png"
        ></img>
        <img
          onClick={() => {
            window.location.href = 'mailto:rob@otlabs.net';
          }}
          className="link-img"
          id="mail-icon"
          alt=""
          src="./images/ui/mail.png"
        ></img>
      </div>
    </div>
  );
};

export default Header;

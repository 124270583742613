const url = './data/portfolio.old.json'
export const fetchData = () => {
  return new Promise((resolve, reject) => {
    window.fetch(url)
      .then(response => {
        return response.json()
      })
      .then(data => {
        resolve((data))
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  })
}


import React, { Component, useRef, useEffect, useState } from 'react';
import gsap from 'gsap';
import './styles.css';
class PortfolioContentCard extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
    this.myRef = React.createRef();
  }

  componentDidMount = async () => {
    this.elCover = this.myRef.current.getElementsByClassName('item-cover');
    this.elCopy = this.myRef.current.getElementsByClassName('copy');
    this.elImg = this.myRef.current.getElementsByClassName('image');
    this.myRef.current.onmouseover = this.myRef.current.onmouseout = this.handler;
    this.init();
  };

  init() {}

  handler = event => {
    let type = event.type;
    if (!this.props.item.copyOver) {
      return;
    }
    switch (type) {
      case 'mouseover':
        gsap.killTweensOf(this.elCopy);
        gsap.killTweensOf(this.elCover);
        gsap.killTweensOf(this.elImg);
        gsap.to(this.elCover, {
          duration: 0.3,
          opacity: 0.98,
        });
        // gsap.to(this.elCover, {
        //   duration: 0.3,
        //   height: '100%',
        // });

        gsap.to(this.elImg, {
          duration: 0.3,
          scale: 1.05,
        });
        gsap.to(this.elCopy, {
          duration: 0.4,
          opacity: 1,
          marginTop: -20,
        });
        gsap.to(this.myRef.current, {
          duration: 0.4,
          scale: 1.03,
        });
        break;
      case 'mouseout':
        gsap.killTweensOf(this.elCopy);
        gsap.killTweensOf(this.elCover);
        gsap.killTweensOf(this.elImg);
        gsap.to(this.elCover, {
          duration: 0.4,
          opacity: 0,
        });
        gsap.to(this.elImg, {
          duration: 0.3,
          scale: 1,
        });
        gsap.to(this.elCopy, {
          duration: 0.4,
          opacity: 0,
          marginTop: 0,
        });
        gsap.to(this.myRef.current, {
          duration: 0.4,
          scale: 1,
        });
        break;
      default:
    }
    return false;
  };

  getSrc() {
    let src = null;
    if (this.props.item.imgType === 'url') {
      src = this.props.item.img;
    } else {
      src = './images/portfolio/' + this.props.item.img;
    }
    return src;
  }

  render() {
    const { item } = this.props;
    return (
      <div
        ref={this.myRef}
        key={item.index}
        className={item.type + ' item-content shadow-lg'}
      >
        <img
          className="image"
          width="200"
          height="100"
          src={this.getSrc()}
          alt=""
        />
        <div className="item-cover" />
        <div className="copy">
          <div className="copy-name">{item.name}</div>
          <div
            className="copy-subtitle"
            dangerouslySetInnerHTML={{ __html: item.copyOver }}
          ></div>
        </div>
      </div>
    );
  }
}

export default PortfolioContentCard;

export const ContentTypeIDs = {
  CENTER_IMAGE: 'center-image',
  CENTER_TEXT: 'center-text',
  CENTER_TEXT_TWO: 'center-text-two',
  FULL_IMAGE: 'full-image',
  LINK: 'link',
  VIDEO: 'video',
  GRID: 'grid',
  COPY_PROJECT: 'copy-project',
};

import React, { Component } from 'react';
import PortfolioContentCard from './portfolio-content-card';
import { ContentTypeIDs } from './../utils/IDs';
import VideoItem from './video-item';
import gsap from 'gsap';
import ReactPlayer from 'react-player';
class PortfolioContent extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
    this.myRef = React.createRef();
  }

  componentDidMount = async () => {
    // this.init();
  };
  // clicked = () => {
  //   this.props.father.itemRequestHome();
  // };
  createContent = contentArray => {
    const loadingContent = document.getElementById('loadingContent');
    this.contentArray = contentArray;
    loadingContent.style.visibility = 'visible';
    this.forceUpdate();
    this.myRef.current.style.visibility = 'visible';
    for (let i = 0; i < this.myRef.current.children.length; i++) {
      const child = this.myRef.current.children[i];
      gsap.set(child, {
        alpha: 0,
      });
      gsap.to(child, {
        duration: 0.8,
        delay: i / 6,
        alpha: 1,
        onComplete: () => {
          if (i === 0) {
            loadingContent.style.visibility = 'hidden';
          }
        },
      });
    }
  };
  emptyContent = () => {
    this.contentArray = [];
    const loadingContent = document.getElementById('loadingContent');
    loadingContent.style.visibility = 'visible';
    this.forceUpdate();
  };

  renderContentGrid = item => {
    let listItems = null;
    if (this.contentArray) {
      listItems = item.gridContent.map((item, index) => {
        item.index = index;
        return (
          <PortfolioContentCard
            key={index + item.img}
            item={item}
            father={this}
          ></PortfolioContentCard>
        );
      });
    }
    return listItems;
  };
  renderContent = () => {
    let listContentArray = null;
    if (this.contentArray) {
      listContentArray = this.contentArray.map((item, index) => {
        switch (item.type) {
          case ContentTypeIDs.CENTER_IMAGE:
            const src = './images/portfolio/' + item.img;
            return (
              <div className="center-image" key={index}>
                <img
                  className="image"
                  // width="200"
                  // height="100"
                  src={src}
                  alt=""
                />
              </div>
            );
          case ContentTypeIDs.FULL_IMAGE:
            const srcFull = './images/portfolio/' + item.img;
            return (
              <div className="full-image" key={index}>
                <img
                  className="image"
                  // width="200"
                  // height="100"
                  src={srcFull}
                  alt=""
                />
              </div>
            );
          case ContentTypeIDs.VIDEO:
            if (item.host === 'vimeo') {
              if (item.videoProportion) {
                setTimeout(() => {
                  const videoDiv = document.getElementById(
                    'videovimeo' + index
                  );
                  videoDiv.style.paddingTop = item.videoProportion + '%';
                }, 200);
              }
              return (
                <div className="video-container-vimeo-outer" key={index}>
                  <div
                    className="video-container-vimeo"
                    id={'videovimeo' + index}
                  >
                    <ReactPlayer
                      className="react-player"
                      controls={true}
                      playing={true}
                      autoPlay={true}
                      url={item.videoUrl}
                      muted={true}
                      width="100%"
                      height="100%"
                      loop={true}
                    />
                  </div>
                </div>
              );
            } else {
              const srcVideo = './video/' + item.video;
              return <VideoItem key={index} video={srcVideo}></VideoItem>;
            }

          case ContentTypeIDs.GRID:
            return (
              <div key={index} className="grid-wrapper-content" id="all">
                {this.renderContentGrid(item)}
              </div>
            );
          case ContentTypeIDs.COPY_PROJECT:
            let classStrCopy = 'copy-container';
            if (item.copy.color === 'white') {
              classStrCopy = 'copy-container white';
            }
            return (
              <div key={index} className={classStrCopy}>
                <div>
                  <span className="copy-title">Role:</span> {item.copy.role}
                </div>
                <div>
                  <span className="copy-title">Techs:</span> {item.copy.techs}
                </div>
                <div>
                  <span className="copy-title">Client:</span> {item.copy.client}
                </div>
                <div>
                  <span className="copy-title">Project:</span>{' '}
                  {item.copy.project}
                </div>
                <div className="copy-more">
                  {item.copy.copyMore && <div>{item.copy.copyMore}</div>}
                </div>
              </div>
            );
          case ContentTypeIDs.CENTER_TEXT:
            let classStr = 'copy-center-text';
            if (item.color === 'white') {
              classStr = 'copy-center-text white';
            }
            if (item.color === 'black') {
              classStr = 'copy-center-text black';
            }
            return (
              <div key={index} className={classStr}>
                {item.copy}
              </div>
            );
          case ContentTypeIDs.CENTER_TEXT_TWO:
            let classStr2 = 'copy-center-text two';
            if (item.color === 'white') {
              classStr2 = 'copy-center-text two white';
            }
            if (item.color === 'black') {
              classStr2 = 'copy-center-text two black';
            }
            return (
              <div key={index} className={classStr2}>
                {item.copy}
              </div>
            );
          case ContentTypeIDs.LINK:
            let classStrLink = 'content-link';
            if (item.color === 'white') {
              classStrLink = 'content-link white';
            }
            return (
              <div
                key={index}
                className={classStrLink}
                onClick={() => {
                  window.open(item.url);
                }}
              >
                {'>> ' + item.copy}
              </div>
            );
          default:
        }
      });
      return listContentArray;
    }
  };

  render() {
    return (
      <>
        <div id="loadingContent" className="loading">
          <img
            className="loading-img"
            alt=""
            src="./images/ui/loading-content-w.gif"
          ></img>
        </div>

        <div
          ref={this.myRef}
          className="portfolio-content-inner"
          // onClick={this.clicked}
        >
          {this.renderContent()}
          <div className="footer"></div>
        </div>
      </>
    );
  }
}

export default PortfolioContent;

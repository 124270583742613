import React, { Component, useRef, useEffect, useState } from 'react';
import gsap from 'gsap';
import './styles.css';
class VideoItem extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      muted: true,
      playing: true,
    };
    this.myRef = React.createRef();
    this.myRefContainer = React.createRef();
  }

  componentDidMount = async () => {
    this.init();
  };

  init() {
    const videoContainer = this.myRefContainer.current;
    const videoOverlay = this.myRefContainer.current.getElementsByClassName(
      'video-overlay'
    )[0];
    const videoTimeline = this.myRefContainer.current.getElementsByClassName(
      'timeline'
    )[0];
    const videoTimelineBack = this.myRefContainer.current.getElementsByClassName(
      'timeline-back'
    )[0];
    const videoTimelineCover = this.myRefContainer.current.getElementsByClassName(
      'timeline-cover'
    )[0];

    gsap.to(videoOverlay, {
      duration: 0.2,
      opacity: 0,
      delay: 2,
    });
    // videoContainer.onmouseout = () => {
    //   videoOverlay.style.opacity = 0;
    // };
    // videoContainer.onmouseover = () => {

    // };
    // videoContainer.onClick = () => {};
    gsap.to(videoTimeline, {
      duration: 1,
      x: 0,
      width: this.myRefContainer.current.offsetWidth - 60,
    });

    videoContainer.addEventListener('mouseout', event => {
      gsap.killTweensOf(videoOverlay);
      gsap.to(videoOverlay, {
        duration: 0.3,
        opacity: 0,
      });
    });

    videoContainer.addEventListener('mousemove', event => {
      // videoOverlay.style.opacity = 1;
      // gsap.set(videoOverlay, { opacity: 1 });
      gsap.killTweensOf(videoOverlay);
      gsap.to(videoOverlay, {
        duration: 0.5,
        opacity: 1,
        onComplete: () => {
          gsap.to(videoOverlay, {
            duration: 0.2,
            opacity: 0,
            delay: 2,
          });
        },
      });
    });
    videoContainer.addEventListener('click', e => {
      e.stopPropagation();
    });

    const audioBt = this.myRefContainer.current.getElementsByClassName(
      'audio-bt'
    )[0];
    const playBt = this.myRefContainer.current.getElementsByClassName(
      'play-bt'
    )[0];
    audioBt.addEventListener('mouseout', e => {
      e.target.style.opacity = 1;
    });
    audioBt.addEventListener('mouseover', e => {
      e.target.style.opacity = 0.7;
    });
    audioBt.addEventListener('click', e => {
      e.stopPropagation();
      if (this.state.muted) {
        const allvideos = document.getElementsByClassName('video-itself');
        for (let i = 0; i < allvideos.length; i++) {
          const video = allvideos[i];
          video.muted = true;
        }
      }
      this.setState({ muted: !this.state.muted });
    });

    playBt.addEventListener('mouseout', e => {
      e.target.style.opacity = 1;
    });
    playBt.addEventListener('mouseover', e => {
      e.target.style.opacity = 0.7;
    });
    playBt.addEventListener('click', e => {
      e.stopPropagation();
      this.setState({ playing: !this.state.playing });
    });
    videoTimeline.addEventListener('click', e => {
      var rect = e.target.getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      var y = e.clientY - rect.top; //y position within the element.
      const totalW = videoTimeline.getBoundingClientRect().width;
      const percClicked = x / totalW;
      this.myRef.current.currentTime =
        this.myRef.current.duration * percClicked;
    });

    setInterval(() => {
      if (this.myRef.current) {
        const perc =
          this.myRef.current.currentTime / this.myRef.current.duration;
        gsap.to(videoTimelineCover, {
          width: perc * 100 + '%',
          duration: 0.2,
        });

        if (this.myRef.current.muted && !this.state.muted) {
          this.setState({ muted: true });
        }
      }
    }, 100);

    gsap.to(videoTimelineBack, {
      width: '100%',
      duration: 0.2,
    });

    this.myRef.current.addEventListener('waiting', () => {
      const bufferingDiv = this.myRefContainer.current.getElementsByClassName(
        'video-buffering'
      )[0];
      gsap.to(bufferingDiv, {
        duration: 0.4,
        alpha: 1,
      });
    });
    this.myRef.current.addEventListener('playing', () => {
      const bufferingDiv = this.myRefContainer.current.getElementsByClassName(
        'video-buffering'
      )[0];
      gsap.to(bufferingDiv, {
        duration: 0.5,
        alpha: 0,
      });
    });
  }

  componentDidUpdate() {
    this.myRef.current.muted = this.state.muted;
    if (this.state.playing) {
      this.myRef.current.play();
    } else {
      this.myRef.current.pause();
    }
  }

  render() {
    return (
      <div
        ref={this.myRefContainer}
        id="videoContainer"
        className="video-container"
      >
        <video
          preload="none"
          className="video-itself"
          ref={this.myRef}
          src={this.props.video}
          controls={false}
          loop
          autoPlay
          muted
          type="video/mp4"
        />
        <div className="video-buffering">
          <img
            className="loading-img video-buffering-img"
            alt=""
            src="./images/ui/l-1.gif"
          ></img>
        </div>
        <div id="videoOverlay" className="video-overlay">
          <img
            className="video-ui-icons audio-bt"
            alt=""
            src={
              this.state.muted
                ? './images/ui/audio-on.png'
                : './images/ui/audio-off.png'
            }
          ></img>
          <img
            className="video-ui-icons play-bt"
            alt=""
            src="./images/ui/play-pause.png"
          ></img>
          <div className="timeline">
            <div className="timeline-back"></div>
            <div className="timeline-cover"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoItem;
